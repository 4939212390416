import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import "@fontsource/roboto/500.css";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from '@mui/material/ListItemIcon';
import { Button, ListItemButton } from "@mui/material";
import DrawerManager from "./DrawerManager";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateConfig } from "../app/configSlice";
import { useHistory } from "react-router-dom";
import Deploy from '../icons/Deploy.png';
import Event from '../icons/Event.png';
import Help from '../icons/Help.png';
import Local from '../icons/Local.png';
import Messages from '../icons/Messages.png';
import Misc from '../icons/Misc.png';
import Simulate from '../icons/Simulate.png';
import Logo from '../icons/Logo.png';
import Animation from '../icons/Animation.gif';

const drawerWidth = 240;

const featureList = [
  "Dynamic Messages",
  "Special Event",
  "Help",
  "Local Messages",
  "Misc",
];

const iconList = [
  Messages,
  Event,
  Help,
  Local,
  Misc,
];

function MainPage() {
  const [pageIndex, setPageIndex] = useState(0);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    console.log("use effect test");
    dispatch(updateConfig());
  }, []);

  const handleClick = (index) => {
    console.log("this is the index ", index);
    setSelectedIndex(index);
    setPageIndex(index);
  };

  return (
    <Box sx={{ display: "flex"}}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar disableGutters>
          <img src={Animation} width="90"></img>
          <img src={Logo} width="190"></img>
          <Box sx={{ flexGrow: 1 }}>
          </Box>
          <Box>
            <Button
              sx={{ color: "white" }}
              variant="outlined"
              onClick={() => {
                localStorage.clear();
                history.push("/login");
              }}
            >
              Log out
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: "auto" }}>
          <List>
            {featureList.map((text, index) => (
              <ListItemButton
                className = "drawerItem"
                selected={selectedIndex === index}
                sx={{
                  "&.Mui-selected": {
                    background: "#f5f5f5",
                    color: "#1876D1",
                  },
                }}
                key={text}
                onClick={() => {
                  handleClick(index);
                }}
              >
                <ListItemIcon>
                  <img src={iconList[index]} width="50%"></img>
                </ListItemIcon>
                <ListItemText primary={text} sx={{ margin: -2.5 }} />
              </ListItemButton>
            ))}
          </List>
          <Divider />
          <List>
            <ListItemButton
              button
              key="Simulate"
              selected={selectedIndex === 98}
              sx={{
                "&.Mui-selected": {
                  background: "#f5f5f5",
                  color: "#1876D1",
                },
              }}
              onClick={() => {
                handleClick(98);
              }}
            >
              <ListItemIcon>
                <img src={Simulate} width="50%"></img>
              </ListItemIcon>
              <ListItemText primary="Simulate" sx={{ margin: -2.5 }} />
            </ListItemButton>
            <ListItemButton
              button
              key="Deployment"
              selected={selectedIndex === 99}
              sx={{
                "&.Mui-selected": {
                  background: "#f5f5f5",
                  color: "#1876D1",
                },
              }}
              onClick={() => {
                handleClick(99);
              }}
            >
              <ListItemIcon>
                <img src={Deploy} width="50%"></img>
              </ListItemIcon>
              <ListItemText primary="Deployment" sx={{ margin: -2.5 }} />
            </ListItemButton>
          </List>
        </Box>
      </Drawer>
      <Box>

      </Box>
      <Box component="main" sx={{ flexGrow: 1, p: 3, backgroundColor: "#f5f5f5", minHeight:"100vh"}} >
        <Toolbar />
        <DrawerManager page={pageIndex} />
      </Box>
    </Box>
  );
}

export default MainPage;

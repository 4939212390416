import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Box, TextField, Button } from "@mui/material";
import { SERVER_URL } from "../app/constant";

export const ForgotPasswordPage = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [emailValue, setEmailValue] = useState("");
  const history = useHistory();

  const onSubmitClicked = async () => {
    try {
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: emailValue,
        }),
      };

      await fetch(`${SERVER_URL}/forgot-password`, requestOptions).then(
        (response) => {
          setSuccess(true);
          setTimeout(() => {
            history.push(
              `/reset-password?email=${encodeURIComponent(emailValue)}`
            );
          }, 3000);
        }
      );
    } catch (e) {
      setErrorMessage(e.message);
    }
  };

  return success ? (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <h1 style={{ color: "#3366BB" }}>Success</h1>
      <p>Check your email for a reset link</p>
    </Box>
  ) : (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <h1 style={{ color: "#3366BB" }}>Forgot Password</h1>

      <p>Enter your email and we'll send you a reset link</p>
      {errorMessage && <div className="fail">{errorMessage}</div>}
      <TextField
        sx={{ width: "300px", mt: 2 }}
        id="outlined-required"
        label="Email"
        value={emailValue}
        onChange={(e) => setEmailValue(e.target.value)}
      />
      <Button
        variant="contained"
        sx={{ width: "300px", mt: 2 }}
        disabled={!emailValue}
        onClick={onSubmitClicked}
      >
        Send Reset Link
      </Button>
    </Box>
  );
};

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import MainPage from "./components/MainPage";
import { LogInPage } from "./auth-components/LogInPage";
import { SignUpPage } from "./auth-components/SignUpPage";
import { EmailVerificationCodePage } from "./auth-components/EmailVerificationCodePage";
import { ForgotPasswordPage } from "./auth-components/ForgotPasswordPage";
import { PasswordResetLandingPage } from "./auth-components/PasswordResetLandingPage";
import { PleaseVerifyEmailPage } from "./auth-components/PleaseVerifyEmailPage";
import { EmailVerificationLandingPage } from "./auth-components/EmailVerificationLandingPage";
import { PrivateRoute } from "./auth/PrivateRoute";

function App() {
  return (
    <Router>
      <Switch>
        <PrivateRoute path="/" exact>
          <MainPage />
        </PrivateRoute>
        <Route path="/login" exact>
          <LogInPage />
        </Route>
        <Route path="/signup" exact>
          <SignUpPage />
        </Route>
        <Route path="/verify-email" exact>
          <EmailVerificationCodePage />
        </Route>
        <Route path="/verify-email/:verificationString">
          <EmailVerificationLandingPage />
        </Route>
        <Route path="/forgot-password">
          <ForgotPasswordPage />
        </Route>{" "}
        <Route path="/please-verify">
          <PleaseVerifyEmailPage />
        </Route>
        <Route path="/reset-password">
          <PasswordResetLandingPage />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;

import { configureStore } from "@reduxjs/toolkit";
import configReducer from "./configSlice";
import appReducer from "./appSlice";

export default configureStore({
  reducer: {
    config: configReducer,
    app: appReducer,
  },
});

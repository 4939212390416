import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { initialState } from "./initialState";
import { QA_CONFIG } from "./constant";

export const updateConfig = createAsyncThunk(
  "config/update",
  async (thunkAPI) => {
    const data = await fetch(QA_CONFIG, {
      headers: {
        "cache-control": "no-cache",
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .catch((error) => {
        console.log(error.message);
        return thunkAPI.RejectWithValue(error);
      });
    return data;
  }
);

export const updateConfigFromFile = createAsyncThunk(
  "config/updatefromfile",
  async (event, thunkAPI) => {
    const fileReaderPromise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsText(event.target.files[0], "UTF-8");
      fileReader.onload = (event) => {
        const content = event.target.result;
        resolve(content);
      };
      fileReader.onerror = () => {
        reject();
      };
    });

    const data = await fileReaderPromise
      .then((content) => JSON.parse(content))
      .catch((error) => {
        console.log(error.message);
        return thunkAPI.RejectWithValue(error);
      });
    return data;
  }
);

export const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    updateWelcomeMessage: (state, action) => {
      state.welcomeMessage = action.payload;
    },
    updateScreenWelcomeMessage: (state, action) => {
      state.screenWelcomeMessage = action.payload;
    },
    updateEditorialContent: (state, action) => {
      state.editorialcontent = action.payload;
    },
    updatePlaylistIntroMessageFrequency: (state, action) => {
      state.playlistIntroMessageFrequency = action.payload;
    },
    updatePlaylistIntroMessage: (state, action) => {
      state.playlistIntroMessage = action.payload;
    },
    updateRadioOneIntroMessageFrequency: (state, action) => {
      state.radioOneIntroMessageFrequency = action.payload;
    },
    updateRadioOneIntroMessage: (state, action) => {
      state.radioOneIntroMessage = action.payload;
    },
    updateNewFeature: (state, action) => {
      state.newFeature = action.payload;
    },
    updateSpecialEventStatus: (state, action) => {
      state.status = action.payload;
    },
    updateTitle: (state, action) => {
      state.title = action.payload;
    },
    udpateIntro: (state, action) => {
      state.intro = action.payload;
    },
    updateDescription: (state, action) => {
      state.description = action.payload;
    },
    updateVideoUrl: (state, action) => {
      state.videoUrl = action.payload;
    },
    updateAudioUrl: (state, action) => {
      state.audioUrl = action.payload;
    },
    updateContentArea: (state, action) => {
      state.content_area = action.payload;
    },
    updateHelpMessagePlaylist: (state, action) => {
      state.helpMessage_playlist = action.payload;
    },
    updateScreenHelpMessagePlaylist: (state, action) => {
      state.helpMessage_playlist_screen_text = action.payload;
    },
    updateHelpMessagePodcast: (state, action) => {
      state.helpMessage_podcast = action.payload;
    },
    updateScreenHelpMessagePodcast: (state, action) => {
      state.helpMessage_podcast_screen_text = action.payload;
    },
    updateTorontoWelcomeMessage: (state, action) => {
      state.toronto_welcomeMessage = action.payload;
    },
    updateBarrieWelcomeMessage: (state, action) => {
      state.barrie_welcomeMessage = action.payload;
    },
    updatePeterboroughWelcomeMessage: (state, action) => {
      state.peterborough_welcomeMessage = action.payload;
    },
    updateKingstonWelcomeMessage: (state, action) => {
      state.kingston_welcomeMessage = action.payload;
    },
    updateSaskatoonWelcomeMessage: (state, action) => {
      state.saskatoon_welcomeMessage = action.payload;
    },
    updateKitchenerWelcomeMessage: (state, action) => {
      state.kitchener_welcomeMessage = action.payload;
    },
    updateKamloopWelcomeMessage: (state, action) => {
      state.kamloops_welcomeMessage = action.payload;
    },
    updateVictoriaWelcomeMessage: (state, action) => {
      state.victorial_welcomeMessage = action.payload;
    },
    updatePrinceGeorgeWelcomeMessage: (state, action) => {
      state.princegeorge_welcomeMessage = action.payload;
    },
    updateKelownaWelcomeMessage: (state, action) => {
      state.kelowna_welcomeMessage = action.payload;
    },
    updateWhitehorseWelcomeMessage: (state, action) => {
      state.whitehorse_welcomeMessage = action.payload;
    },
    updateYellowknifeWelcomeMessage: (state, action) => {
      state.yellowknife_welcomeMessage = action.payload;
    },
    updateVancouverWelcomeMessage: (state, action) => {
      state.vancouver_welcomeMessage = action.payload;
    },
    updateReginaWelcomeMessage: (state, action) => {
      state.regina_welcomeMessage = action.payload;
    },
    updateInuvikWelcomeMessage: (state, action) => {
      state.inuvik_welcomeMessage = action.payload;
    },
    updateEdmontonWelcomeMessage: (state, action) => {
      state.edmonton_welcomeMessage = action.payload;
    },
    updateCalgaryWelcomeMessage: (state, action) => {
      state.calgary_welcomeMessage = action.payload;
    },
    updateWinnipegWelcomeMessage: (state, action) => {
      state.winnipeg_welcomeMessage = action.payload;
    },
    updateThunderbayWelcomeMessage: (state, action) => {
      state.thunderbay_welcomeMessage = action.payload;
    },
    updateQuebecWelcomeMessage: (state, action) => {
      state.quebec_welcomeMessage = action.payload;
    },
    updateKitchenerWaterlooWelcomeMessage: (state, action) => {
      state.kitchenerwaterloo_welcomeMessage = action.payload;
    },
    updateCornerbrookWelcomeMessage: (state, action) => {
      state.cornerbrook_welcomeMessage = action.payload;
    },
    updateFrederictonWelcomeMessage: (state, action) => {
      state.fredericton_welcomeMessage = action.payload;
    },
    updateGoosebayWelcomeMessage: (state, action) => {
      state.goosebay_welcomeMessage = action.payload;
    },
    updateMonctonWelcomeMessage: (state, action) => {
      state.moncton_welcomeMessage = action.payload;
    },
    updateNewfoundlandWelcomeMessage: (state, action) => {
      state.newfoundland_welcomeMessage = action.payload;
    },
    updateCharlottetownWelcomeMessage: (state, action) => {
      state.charlottetown_welcomeMessage = action.payload;
    },
    updateLondonWelcomeMessage: (state, action) => {
      state.london_welcomeMessage = action.payload;
    },
    updateOttawaWelcomeMessage: (state, action) => {
      state.ottawa_welcomeMessage = action.payload;
    },
    updateNewbrunswickWelcomeMessage: (state, action) => {
      state.newbrunswick_welcomeMessage = action.payload;
    },
    updateSudburyWelcomeMessage: (state, action) => {
      state.sudbury_welcomeMessage = action.payload;
    },
    updateWindsorWelcomeMessage: (state, action) => {
      state.windsor_welcomeMessage = action.payload;
    },
    updateIqualuitWelcomeMessage: (state, action) => {
      state.iqaluit_welcomeMessage = action.payload;
    },
    updateMontrealWelcomeMessage: (state, action) => {
      state.montreal_welcomeMessage = action.payload;
    },
    updatePlaylistPowerUserThreshold: (state, action) => {
      state.playlistPowerUserThreshold = action.payload;
    },
    updatePlaylistPowerUserWelcomeMessage: (state, action) => {
      state.playlistPowerUser_welcomeMessage = action.payload;
    },
    updateWelcomeBackground: (state, action) => {
      state.welcomeBackground = action.payload;
    },
    updateNewFeatureBackground: (state, action) => {
      state.newFeatureBackground = action.payload;
    },
    updateOnboardingMessage: (state, action) => {
      state.onboardingMessage = action.payload;
    },
  },
  extraReducers: {
    [updateConfig.fulfilled]: (state, action) => {
      for (const property in action.payload) {
        state[property] = action.payload[property];
      }
    },
    [updateConfigFromFile.fulfilled]: (state, action) => {
      for (const property in action.payload) {
        state[property] = action.payload[property];
      }
    },
  },
});

export const {
  updateWelcomeMessage,
  updateScreenWelcomeMessage,
  updateEditorialContent,
  updatePlaylistIntroMessageFrequency,
  updatePlaylistIntroMessage,
  updateRadioOneIntroMessageFrequency,
  updateRadioOneIntroMessage,
  updateNewFeature,
  updateSpecialEventStatus,
  updateTitle,
  udpateIntro,
  updateDescription,
  updateVideoUrl,
  updateAudioUrl,
  updateContentArea,
  updateHelpMessagePlaylist,
  updateScreenHelpMessagePlaylist,
  updateHelpMessagePodcast,
  updateScreenHelpMessagePodcast,
  updateTorontoWelcomeMessage,
  updateBarrieWelcomeMessage,
  updatePeterboroughWelcomeMessage,
  updateKingstonWelcomeMessage,
  updateSaskatoonWelcomeMessage,
  updateKitchenerWelcomeMessage,
  updateKamloopWelcomeMessage,
  updateVictoriaWelcomeMessage,
  updatePrinceGeorgeWelcomeMessage,
  updateKelownaWelcomeMessage,
  updateWhitehorseWelcomeMessage,
  updateYellowknifeWelcomeMessage,
  updateVancouverWelcomeMessage,
  updateReginaWelcomeMessage,
  updateInuvikWelcomeMessage,
  updateEdmontonWelcomeMessage,
  updateCalgaryWelcomeMessage,
  updateWinnipegWelcomeMessage,
  updateThunderbayWelcomeMessage,
  updateQuebecWelcomeMessage,
  updateKitchenerWaterlooWelcomeMessage,
  updateCornerbrookWelcomeMessage,
  updateFrederictonWelcomeMessage,
  updateGoosebayWelcomeMessage,
  updateMonctonWelcomeMessage,
  updateNewfoundlandWelcomeMessage,
  updateCharlottetownWelcomeMessage,
  updateLondonWelcomeMessage,
  updateOttawaWelcomeMessage,
  updateNewbrunswickWelcomeMessage,
  updateSudburyWelcomeMessage,
  updateWindsorWelcomeMessage,
  updateIqualuitWelcomeMessage,
  updateMontrealWelcomeMessage,
  updatePlaylistPowerUserThreshold,
  updatePlaylistPowerUserWelcomeMessage,
  updateWelcomeBackground,
  updateNewFeatureBackground,
  updateOnboardingMessage
} = configSlice.actions;
export default configSlice.reducer;

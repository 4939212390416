import DynamicMessagePage from "./DynamicMessagePage";
import SpecialEventPage from "./SpecialEventPage";
import LocalMessagePage from "./LocalMessagePage";
import DeploymentPage from "./DeploymentPage";
import SimulatePage from "./SimulatePage";
import HelpPage from "./HelpPage";
import MiscPage from "./MiscPage";

function DrawerManager(props) {
  switch (props.page) {
    case 0:
      return <DynamicMessagePage />;
    case 1:
      return <SpecialEventPage />;
    case 2:
      return <HelpPage />;
    case 3:
      return <LocalMessagePage />;
    case 4:
      return <MiscPage />;
    case 98:
      return <SimulatePage />;
    case 99:
      return <DeploymentPage />;
    default:
      return <p>Something went wrong</p>;
  }
}

export default DrawerManager;

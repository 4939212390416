import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loadConfig: false,
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    loadConfig: (state, action) => {
      state.loadConfig = false;
    },
    configLoaded: (state, action) => {
      state.loadConfig = true;
    },
  },
});

export const { loadConfig, configLoaded } = appSlice.actions;
export default appSlice.reducer;

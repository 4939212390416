import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useToken } from "../auth/useToken";
import { EmailVerificationSuccess } from "./EmailVerificationSuccess";
import { EmailVerificationFail } from "./EmailVerificationFail";
import { SERVER_URL } from "../app/constant";

export const EmailVerificationLandingPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const { verificationString } = useParams();
  const [, setToken] = useToken();

  // useEffect(() => {
  //   const loadVerification = async () => {
  //     try {
  //       const requestOptions = {
  //         method: "PUT",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({
  //           email: emailValue,
  //           password: passwordValue,
  //         }),
  //       };

  //       await fetch(SERVER_URL, requestOptions)
  //         .then((response) => response.json())
  //         .then((data) => {
  //           console.log("this is token ", data.token);
  //           setToken(data.token);
  //           setIsSuccess(true);
  //           setIsLoading(false);
  //         });
  //     } catch (e) {
  //       setIsSuccess(false);
  //       setIsLoading(false);
  //     }
  //   };

  //   loadVerification();
  // }, [setToken, verificationString]);

  if (isLoading) return <p>Loading...</p>;
  if (!isSuccess) return <EmailVerificationFail />;
  return <EmailVerificationSuccess />;
};

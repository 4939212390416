//PROD
export const SERVER_URL = "https://7qy2f4v3aga43rq3ty4ndp4tua0ssvoz.lambda-url.us-east-1.on.aws";
export const QA_CONFIG = "https://cbc-alexa-config.s3.amazonaws.com/config-qa.json";

//QA
// export const SERVER_URL = "https://pvx75qhh2hrxs5vngjm7ktg53i0zmpcp.lambda-url.us-east-1.on.aws";
// export const QA_CONFIG = "https://cbc-alexa-config.s3.amazonaws.com/qa-config-qa.json";

export const ROLE = {
  ADMIN: "admin",
  VIEWER: "viewer",
  PRODUCER: "producer",
};

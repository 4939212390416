export const initialState = {
  newFeature: "",
  welcomeMessage: "",
  screenWelcomeMessage: "",
  onboardingMessage: "",
  editorialcontent: "",
  playlistIntroMessageFrequency: 0,
  playlistIntroMessage: "",
  radioOneIntroMessageFrequency: 0,
  radioOneIntroMessage: "",
  title: "",
  intro: "",
  description: "",
  videoUrl: "",
  audioUrl: "",
  newFeature: "",
  status: false,
  content_area: "",
  helpMessage_playlist: "",
  helpMessage_playlist_screen_text: "",
  helpMessage_podcast: "",
  helpMessage_podcast_screen_text: "",
  toronto_welcomeMessage: "",
  barrie_welcomeMessage: "",
  peterborough_welcomeMessage: "",
  kingston_welcomeMessage: "",
  saskatoon_welcomeMessage: "",
  kitchener_welcomeMessage: "",
  kamloops_welcomeMessage: "",
  victorial_welcomeMessage: "",
  princegeorge_welcomeMessage: "",
  kelowna_welcomeMessage: "",
  whitehorse_welcomeMessage: "",
  yellowknife_welcomeMessage: "",
  vancouver_welcomeMessage: "",
  regina_welcomeMessage: "",
  inuvik_welcomeMessage: "",
  edmonton_welcomeMessage: "",
  calgary_welcomeMessage: "",
  winnipeg_welcomeMessage: "",
  thunderbay_welcomeMessage: "",
  quebec_welcomeMessage: "",
  kitchenerwaterloo_welcomeMessage: "",
  cornerbrook_welcomeMessage: "",
  fredericton_welcomeMessage: "",
  goosebay_welcomeMessage: "",
  moncton_welcomeMessage: "",
  newfoundland_welcomeMessage: "",
  charlottetown_welcomeMessage: "",
  london_welcomeMessage: "",
  ottawa_welcomeMessage: "",
  newbrunswick_welcomeMessage: "",
  sudbury_welcomeMessage: "",
  windsor_welcomeMessage: "",
  iqaluit_welcomeMessage: "",
  montreal_welcomeMessage: "",
  playlistPowerUserThreshold: "",
  playlistPowerUser_welcomeMessage: "",
  routineWelcomeMessage: "",
  welcomeBackground: "",
};

import { useHistory } from "react-router-dom";
import { Box, TextField, Button } from "@mui/material";

export const PasswordResetFail = () => {
  const history = useHistory();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <h1 style={{ color: "#3366BB" }}>Uh oh...</h1>
      <p>Something went wrong while trying to reset your password.</p>
      <button onClick={() => history.push("/login")}>Back to Log in</button>
    </Box>
  );
};

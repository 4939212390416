import { Box, Button } from "@mui/material";
import { useHistory } from "react-router-dom";

export const EmailVerificationSuccess = () => {
  const history = useHistory();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <h1 style={{ color: "#3366BB" }}>Success!</h1>
      <p>
        Thanks for verifying your email, now you can use all the app's features.
      </p>
      <Button variant="contained" onClick={() => history.push("/")}>
        Go to Voice Platform Config
      </Button>
    </Box>
  );
};

import { useHistory } from "react-router-dom";
import { Button, Box } from "@mui/material";

export const EmailVerificationFail = () => {
  const history = useHistory();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <h1 style={{ color: "#3366BB" }}>Uh oh...</h1>
      <p>Something went wrong while trying to verify your email.</p>
      <Button variant="contained" onClick={() => history.push("/signup")}>
        Back to Sign-up
      </Button>
    </Box>
  );
};

import { Box, TextField } from "@mui/material";

import { useSelector, useDispatch } from "react-redux";
import {
  updatePlaylistPowerUserThreshold,
  updatePlaylistPowerUserWelcomeMessage,
} from "../app/configSlice";

function MiscPage() {
  const dispatch = useDispatch();
  const playlistPowerUserThreshold = useSelector(
    (state) => state.config.playlistPowerUserThreshold
  );
  const playlistPowerUser_welcomeMessage = useSelector(
    (state) => state.config.playlistPowerUser_welcomeMessage
  );

  const textFieldStyle = {
    mt: 2,
    backgroundColor: "white",
  }

  return (
    <Box>
      <TextField
        id="outlined-multiline-static"
        label="Playlist power user threshold"
        defaultValue={playlistPowerUserThreshold}
        sx={{ width: 1 / 4, backgroundColor: "white" }}
        onChange={(e) =>
          dispatch(updatePlaylistPowerUserThreshold(e.target.value))
        }
      />
      <TextField
        id="outlined-multiline-static"
        label="Help message playlist screen text"
        multiline
        fullWidth
        sx={textFieldStyle}
        defaultValue={playlistPowerUser_welcomeMessage}
        onChange={(e) =>
          dispatch(updatePlaylistPowerUserWelcomeMessage(e.target.value))
        }
      />
    </Box>
  );
}

export default MiscPage;

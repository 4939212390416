import { useState } from "react";
import { PasswordResetSuccess } from "./PasswordResetSuccess";
import { PasswordResetFail } from "./PasswordResetFail";
import { useQueryParams } from "../hooks/useQueryParams";
import { Box, TextField, Button } from "@mui/material";
import { SERVER_URL } from "../app/constant";

export const PasswordResetLandingPage = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailure, setIsFailure] = useState(false);
  const [passwordValue, setPasswordValue] = useState("");
  const [confirmPasswordValue, setConfirmPasswordValue] = useState("");
  const [passwordResetCode, setPasswordResetCode] = useState("");
  const { email } = useQueryParams();

  const onResetClicked = async () => {
    try {
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: email,
          newPassword: passwordValue,
          code: passwordResetCode,
        }),
      };

      fetch(`${SERVER_URL}/reset-password`, requestOptions).then((response) => {
        setIsSuccess(true);
      });
    } catch (e) {
      setIsFailure(true);
    }
  };

  if (isFailure) return <PasswordResetFail />;
  if (isSuccess) return <PasswordResetSuccess />;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <h1 style={{ color: "#3366BB" }}>Reset Password</h1>
      <p>Please enter a new password</p>
      <TextField
        sx={{ width: "200px", mt: 2 }}
        id="outlined-required"
        label="Password Reset Code"
        value={passwordResetCode}
        onChange={(e) => setPasswordResetCode(e.target.value)}
      />
      <TextField
        sx={{ width: "200px", mt: 2 }}
        id="outlined-required"
        label="Password"
        type="password"
        value={passwordValue}
        onChange={(e) => setPasswordValue(e.target.value)}
      />
      <TextField
        sx={{ width: "200px", mt: 2 }}
        id="outlined-required"
        label="Confirm Password"
        type="password"
        value={confirmPasswordValue}
        onChange={(e) => setConfirmPasswordValue(e.target.value)}
      />
      <Button
        variant="contained"
        sx={{ width: "200px", mt: 2 }}
        disabled={
          !passwordValue ||
          !confirmPasswordValue ||
          passwordValue !== confirmPasswordValue
        }
        onClick={onResetClicked}
      >
        Reset Password
      </Button>
    </Box>
  );
};

import {
  FormControlLabel,
  FormGroup,
  TextField,
  Switch,
  Box,
  FormLabel,
} from "@mui/material";

import { useSelector, useDispatch } from "react-redux";
import {
  updateSpecialEventStatus,
  updateTitle,
  udpateIntro,
  updateDescription,
  updateVideoUrl,
  updateAudioUrl,
  updateContentArea,
} from "../app/configSlice";

function SpecialEventPage() {
  const specialEventStatus = useSelector((state) => state.config.status);
  const specialEventTitle = useSelector((state) => state.config.title);
  const specialEventIntro = useSelector((state) => state.config.intro);
  const specialEventDescription = useSelector(
    (state) => state.config.description
  );
  const specialEventVideoUrl = useSelector((state) => state.config.videoUrl);
  const specialEventAudioUrl = useSelector((state) => state.config.audioUrl);
  const specialEventContentArea = useSelector(
    (state) => state.config.content_area
  );
  const dispatch = useDispatch();

  const textFieldStyle = {
    mt: 2,
    backgroundColor: "white",
  }

  return (
    <Box>
      <FormGroup>
        <FormControlLabel
          label="Special Event Status"
          control={<Switch />}
          checked={specialEventStatus}
          onChange={(e) => dispatch(updateSpecialEventStatus(e.target.checked))}
        />
      </FormGroup>
      <FormLabel>
        <TextField
          id="outlined-multiline-static"
          label="Special event intro message"
          multiline
          fullWidth
          sx={textFieldStyle}
          defaultValue={specialEventIntro}
          onChange={(e) => dispatch(udpateIntro(e.target.value))}
        />
        <TextField
          id="outlined-multiline-static"
          label="Special event title"
          multiline
          fullWidth
          sx={textFieldStyle}
          defaultValue={specialEventTitle}
          onChange={(e) => dispatch(updateTitle(e.target.value))}
        />
        <TextField
          id="outlined-multiline-static"
          label="Special event description"
          multiline
          fullWidth
          sx={textFieldStyle}
          defaultValue={specialEventDescription}
          onChange={(e) => dispatch(updateDescription(e.target.value))}
        />
        <TextField
          id="outlined-multiline-static"
          label="Video Url"
          multiline
          fullWidth
          sx={textFieldStyle}
          defaultValue={specialEventVideoUrl}
          onChange={(e) => dispatch(updateVideoUrl(e.target.value))}
        />
        <TextField
          id="outlined-multiline-static"
          label="Audio Url"
          multiline
          fullWidth
          sx={textFieldStyle}
          defaultValue={specialEventAudioUrl}
          onChange={(e) => dispatch(updateAudioUrl(e.target.value))}
        />
        <TextField
          id="outlined-multiline-static"
          label="Content Area for Tracking"
          multiline
          fullWidth
          sx={textFieldStyle}
          defaultValue={specialEventContentArea}
          onChange={(e) => dispatch(updateContentArea(e.target.value))}
        />
      </FormLabel>
    </Box>
  );
}

export default SpecialEventPage;

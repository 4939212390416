import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Box, TextField, Button } from "@mui/material";
import { SERVER_URL } from "../app/constant";

import { useToken } from "../auth/useToken";
import { useQueryParams } from "../hooks/useQueryParams";

export const LogInPage = () => {
  const [, setToken] = useToken();

  const [errorMessage, setErrorMessage] = useState("");

  const [emailValue, setEmailValue] = useState("");
  const [passwordValue, setPasswordValue] = useState("");

  const history = useHistory();

  const handleCallbackResponse = async (response) => {
    console.log("handled")
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({"token": response.credential}),
    };

    await fetch(`${SERVER_URL}/google-auth`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log("this is token ", data.token);
        setToken(data.token);
        history.push("/");
      });
  }

  useEffect(() => {
    /* global google */
    google.accounts.id.initialize({
      client_id: "949161531967-g19rrq89h0eur9a7ni9nkvuk2sj069rc.apps.googleusercontent.com",
      callback: handleCallbackResponse
    });

    google.accounts.id.renderButton(
      document.getElementById("signInDiv"),
      { theme: "outline", size: "large", width: "300px", mt: 3 }
    );
  }, []);

  const onLogInClicked = async () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: emailValue,
        password: passwordValue,
      }),
    };

    await fetch(`${SERVER_URL}/login`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log("this is token ", data.token);
        setToken(data.token);
        history.push("/");
      });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <h1 style={{ color: "#3366BB" }}>Log In</h1>
      <TextField
        sx={{ width: "300px", mt: 2 }}
        id="outlined-required"
        label="Email"
        value={emailValue}
        onChange={(e) => setEmailValue(e.target.value)}
      />
      <TextField
        sx={{ width: "300px", mt: 2 }}
        id="outlined-required"
        label="Password"
        type="password"
        value={passwordValue}
        onChange={(e) => setPasswordValue(e.target.value)}
      />
      <Button
        variant="contained"
        sx={{ width: "300px", mt: 2 }}
        disabled={!emailValue || !passwordValue}
        onClick={onLogInClicked}
      >
        Log In
      </Button>
      <h4 style={{ color: "#3366BB" }}>OR</h4>
      {<div id="signInDiv"></div>}
      <Button sx={{ mt: 2 }} onClick={() => history.push("/forgot-password")}>
        Forgot your password?
      </Button>
      <Button sx={{ mt: 2 }} onClick={() => history.push("/signup")}>
        Don't have an account? Sign Up
      </Button>
    </Box>
  );
};

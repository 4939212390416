import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useQueryParams } from "../hooks/useQueryParams";
import { Box } from "@mui/material";

export const PleaseVerifyEmailPage = () => {
  const history = useHistory();
  const { email } = useQueryParams();

  useEffect(() => {
    setTimeout(() => {
      history.push(`/verify-email?email=${encodeURIComponent(email)}`);
    }, 3000);
  }, [history, email]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <h1 style={{ color: "#3366BB" }}>Thanks for Signing Up!</h1>

      <p style={{ margin: "40px" }}>
        A verification email has been sent to the email address you provided.
        Please verify your email to unlock full site features.
      </p>
    </Box>
  );
};

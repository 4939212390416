import { useState } from "react";
// import axios from 'axios';
import { Box, TextField, Button } from "@mui/material";
import { EmailVerificationSuccess } from "./EmailVerificationSuccess";
import { EmailVerificationFail } from "./EmailVerificationFail";
import { useToken } from "../auth/useToken";
import { useQueryParams } from "../hooks/useQueryParams";
import { SERVER_URL } from "../app/constant";

export const EmailVerificationCodePage = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailure, setIsFailure] = useState(false);

  const [verificationString, setVerificationString] = useState("");
  const { email } = useQueryParams();
  const [, setToken] = useToken();

  const onSubmitVerificationString = async () => {
    try {
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          verificationString: verificationString,
        }),
      };
      console.log(
        `sending data ${email} and verification string ${verificationString}`
      );
      await fetch(`${SERVER_URL}/verify-email`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          console.log("this is token ", data.token);
          setToken(data.token);
          setIsSuccess(true);
        });
    } catch (e) {
      setIsFailure(true);
    }
  };

  if (isSuccess) return <EmailVerificationSuccess />;
  if (isFailure) return <EmailVerificationFail />;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <h1 style={{ color: "#3366BB" }}>Please Verify Your Email</h1>
      <p>
        You should have received a verification code at the email address you
        provided. Please enter it here:
      </p>
      <TextField
        sx={{ width: "300px", mt: 2 }}
        placeholder="e.g. 123456"
        value={verificationString}
        onChange={(e) => setVerificationString(e.target.value)}
      />
      <Button
        variant="contained"
        sx={{ width: "300px", mt: 2 }}
        onClick={onSubmitVerificationString}
      >
        Submit
      </Button>
    </Box>
  );
};

import { Box, TextField, FormControl, FormLabel } from "@mui/material";

import { useSelector, useDispatch } from "react-redux";
import {
  updateTorontoWelcomeMessage,
  updateBarrieWelcomeMessage,
  updatePeterboroughWelcomeMessage,
  updateKingstonWelcomeMessage,
  updateSaskatoonWelcomeMessage,
  updateKitchenerWelcomeMessage,
  updateKamloopWelcomeMessage,
  updateVictoriaWelcomeMessage,
  updatePrinceGeorgeWelcomeMessage,
  updateKelownaWelcomeMessage,
  updateWhitehorseWelcomeMessage,
  updateYellowknifeWelcomeMessage,
  updateVancouverWelcomeMessage,
  updateReginaWelcomeMessage,
  updateInuvikWelcomeMessage,
  updateEdmontonWelcomeMessage,
  updateCalgaryWelcomeMessage,
  updateWinnipegWelcomeMessage,
  updateThunderbayWelcomeMessage,
  updateQuebecWelcomeMessage,
  updateKitchenerWaterlooWelcomeMessage,
  updateCornerbrookWelcomeMessage,
  updateFrederictonWelcomeMessage,
  updateGoosebayWelcomeMessage,
  updateMonctonWelcomeMessage,
  updateNewfoundlandWelcomeMessage,
  updateCharlottetownWelcomeMessage,
  updateLondonWelcomeMessage,
  updateOttawaWelcomeMessage,
  updateNewbrunswickWelcomeMessage,
  updateSudburyWelcomeMessage,
  updateWindsorWelcomeMessage,
  updateIqualuitWelcomeMessage,
  updateMontrealWelcomeMessage,
} from "../app/configSlice";

function LocalMessagePage() {
  const dispatch = useDispatch();

  const torontoWelcomeMessage = useSelector(
    (state) => state.config.toronto_welcomeMessage
  );
  const barrieWelcomeMessage = useSelector(
    (state) => state.config.barrie_welcomeMessage
  );
  const peterborough_welcomeMessage = useSelector(
    (state) => state.config.peterborough_welcomeMessage
  );
  const kingston_welcomeMessage = useSelector(
    (state) => state.config.kingston_welcomeMessage
  );
  const saskatoon_welcomeMessage = useSelector(
    (state) => state.config.saskatoon_welcomeMessage
  );
  const kitchener_welcomeMessage = useSelector(
    (state) => state.config.kitchener_welcomeMessage
  );
  const kamloops_welcomeMessage = useSelector(
    (state) => state.config.kamloops_welcomeMessage
  );
  const victorial_welcomeMessage = useSelector(
    (state) => state.config.victorial_welcomeMessage
  );
  const princegeorge_welcomeMessage = useSelector(
    (state) => state.config.princegeorge_welcomeMessage
  );
  const kelowna_welcomeMessage = useSelector(
    (state) => state.config.kelowna_welcomeMessage
  );
  const whitehorse_welcomeMessage = useSelector(
    (state) => state.config.whitehorse_welcomeMessage
  );
  const yellowknife_welcomeMessage = useSelector(
    (state) => state.config.yellowknife_welcomeMessage
  );
  const vancouver_welcomeMessage = useSelector(
    (state) => state.config.vancouver_welcomeMessage
  );
  const regina_welcomeMessage = useSelector(
    (state) => state.config.regina_welcomeMessage
  );
  const inuvik_welcomeMessage = useSelector(
    (state) => state.config.inuvik_welcomeMessage
  );
  const edmonton_welcomeMessage = useSelector(
    (state) => state.config.edmonton_welcomeMessage
  );
  const calgary_welcomeMessage = useSelector(
    (state) => state.config.calgary_welcomeMessage
  );
  const winnipeg_welcomeMessage = useSelector(
    (state) => state.config.winnipeg_welcomeMessage
  );
  const thunderbay_welcomeMessage = useSelector(
    (state) => state.config.thunderbay_welcomeMessage
  );
  const quebec_welcomeMessage = useSelector(
    (state) => state.config.quebec_welcomeMessage
  );
  const kitchenerwaterloo_welcomeMessage = useSelector(
    (state) => state.config.kitchenerwaterloo_welcomeMessage
  );
  const cornerbrook_welcomeMessage = useSelector(
    (state) => state.config.cornerbrook_welcomeMessage
  );
  const fredericton_welcomeMessage = useSelector(
    (state) => state.config.fredericton_welcomeMessage
  );
  const goosebay_welcomeMessage = useSelector(
    (state) => state.config.goosebay_welcomeMessage
  );
  const moncton_welcomeMessage = useSelector(
    (state) => state.config.moncton_welcomeMessage
  );
  const newfoundland_welcomeMessage = useSelector(
    (state) => state.config.newfoundland_welcomeMessage
  );
  const charlottetown_welcomeMessage = useSelector(
    (state) => state.config.charlottetown_welcomeMessage
  );
  const london_welcomeMessage = useSelector(
    (state) => state.config.london_welcomeMessage
  );
  const ottawa_welcomeMessage = useSelector(
    (state) => state.config.ottawa_welcomeMessage
  );
  const newbrunswick_welcomeMessage = useSelector(
    (state) => state.config.newbrunswick_welcomeMessage
  );
  const sudbury_welcomeMessage = useSelector(
    (state) => state.config.sudbury_welcomeMessage
  );
  const windsor_welcomeMessage = useSelector(
    (state) => state.config.windsor_welcomeMessage
  );
  const iqaluit_welcomeMessage = useSelector(
    (state) => state.config.iqaluit_welcomeMessage
  );
  const montreal_welcomeMessage = useSelector(
    (state) => state.config.montreal_welcomeMessage
  );

  const textFieldStyle = {
    mt: 2,
    backgroundColor: "white",
  }

  return (
    <Box>
      <TextField
        id="outlined-multiline-static"
        label="Toronto welcome message"
        multiline
        fullWidth
        sx={textFieldStyle}
        defaultValue={torontoWelcomeMessage}
        onChange={(e) => dispatch(updateTorontoWelcomeMessage(e.target.value))}
      />
      <TextField
        id="outlined-multiline-static"
        label="Barrie welcome message"
        multiline
        fullWidth
        sx={textFieldStyle}
        defaultValue={barrieWelcomeMessage}
        onChange={(e) => dispatch(updateBarrieWelcomeMessage(e.target.value))}
      />
      <TextField
        id="outlined-multiline-static"
        label="Peterborough welcome message"
        multiline
        fullWidth
        sx={textFieldStyle}
        defaultValue={peterborough_welcomeMessage}
        onChange={(e) =>
          dispatch(updatePeterboroughWelcomeMessage(e.target.value))
        }
      />
      <TextField
        id="outlined-multiline-static"
        label="Kingston welcome message"
        multiline
        fullWidth
        sx={textFieldStyle}
        defaultValue={kingston_welcomeMessage}
        onChange={(e) => dispatch(updateKingstonWelcomeMessage(e.target.value))}
      />
      <TextField
        id="outlined-multiline-static"
        label="Saskatoon welcome message"
        multiline
        fullWidth
        sx={textFieldStyle}
        defaultValue={saskatoon_welcomeMessage}
        onChange={(e) =>
          dispatch(updateSaskatoonWelcomeMessage(e.target.value))
        }
      />
      <TextField
        id="outlined-multiline-static"
        label="Kitchener welcome message"
        multiline
        fullWidth
        sx={textFieldStyle}
        defaultValue={kitchener_welcomeMessage}
        onChange={(e) =>
          dispatch(updateKitchenerWelcomeMessage(e.target.value))
        }
      />
      <TextField
        id="outlined-multiline-static"
        label="Kamloops welcome message"
        multiline
        fullWidth
        sx={textFieldStyle}
        defaultValue={kamloops_welcomeMessage}
        onChange={(e) => dispatch(updateKamloopWelcomeMessage(e.target.value))}
      />
      <TextField
        id="outlined-multiline-static"
        label="Victoria welcome message"
        multiline
        fullWidth
        sx={textFieldStyle}
        defaultValue={victorial_welcomeMessage}
        onChange={(e) => dispatch(updateVictoriaWelcomeMessage(e.target.value))}
      />
      <TextField
        id="outlined-multiline-static"
        label="Prince George welcome message"
        multiline
        fullWidth
        sx={textFieldStyle}
        defaultValue={princegeorge_welcomeMessage}
        onChange={(e) =>
          dispatch(updatePrinceGeorgeWelcomeMessage(e.target.value))
        }
      />
      <TextField
        id="outlined-multiline-static"
        label="Kelowna welcome message"
        multiline
        fullWidth
        sx={textFieldStyle}
        defaultValue={kelowna_welcomeMessage}
        onChange={(e) => dispatch(updateKelownaWelcomeMessage(e.target.value))}
      />
      <TextField
        id="outlined-multiline-static"
        label="Whitehorse welcome message"
        multiline
        fullWidth
        sx={textFieldStyle}
        defaultValue={whitehorse_welcomeMessage}
        onChange={(e) =>
          dispatch(updateWhitehorseWelcomeMessage(e.target.value))
        }
      />
      <TextField
        id="outlined-multiline-static"
        label="Yellowknife welcome message"
        multiline
        fullWidth
        sx={textFieldStyle}
        defaultValue={yellowknife_welcomeMessage}
        onChange={(e) =>
          dispatch(updateYellowknifeWelcomeMessage(e.target.value))
        }
      />
      <TextField
        id="outlined-multiline-static"
        label="Vancouver welcome message"
        multiline
        fullWidth
        sx={textFieldStyle}
        defaultValue={vancouver_welcomeMessage}
        onChange={(e) =>
          dispatch(updateVancouverWelcomeMessage(e.target.value))
        }
      />
      <TextField
        id="outlined-multiline-static"
        label="Regina welcome message"
        multiline
        fullWidth
        sx={textFieldStyle}
        defaultValue={regina_welcomeMessage}
        onChange={(e) => dispatch(updateReginaWelcomeMessage(e.target.value))}
      />
      <TextField
        id="outlined-multiline-static"
        label="Inuvik welcome message"
        multiline
        fullWidth
        sx={textFieldStyle}
        defaultValue={inuvik_welcomeMessage}
        onChange={(e) => dispatch(updateInuvikWelcomeMessage(e.target.value))}
      />
      <TextField
        id="outlined-multiline-static"
        label="Edmonton welcome message"
        multiline
        fullWidth
        sx={textFieldStyle}
        defaultValue={edmonton_welcomeMessage}
        onChange={(e) => dispatch(updateEdmontonWelcomeMessage(e.target.value))}
      />
      <TextField
        id="outlined-multiline-static"
        label="Calgary welcome message"
        multiline
        fullWidth
        sx={textFieldStyle}
        defaultValue={calgary_welcomeMessage}
        onChange={(e) => dispatch(updateCalgaryWelcomeMessage(e.target.value))}
      />
      <TextField
        id="outlined-multiline-static"
        label="Winnipeg welcome message"
        multiline
        fullWidth
        sx={textFieldStyle}
        defaultValue={winnipeg_welcomeMessage}
        onChange={(e) => dispatch(updateWinnipegWelcomeMessage(e.target.value))}
      />
      <TextField
        id="outlined-multiline-static"
        label="Thunder bay welcome message"
        multiline
        fullWidth
        sx={textFieldStyle}
        defaultValue={thunderbay_welcomeMessage}
        onChange={(e) =>
          dispatch(updateThunderbayWelcomeMessage(e.target.value))
        }
      />
      <TextField
        id="outlined-multiline-static"
        label="Quebec welcome message"
        multiline
        fullWidth
        sx={textFieldStyle}
        defaultValue={quebec_welcomeMessage}
        onChange={(e) => dispatch(updateQuebecWelcomeMessage(e.target.value))}
      />
      <TextField
        id="outlined-multiline-static"
        label="Kitchener Waterloo welcome message"
        multiline
        fullWidth
        sx={textFieldStyle}
        defaultValue={kitchenerwaterloo_welcomeMessage}
        onChange={(e) =>
          dispatch(updateKitchenerWaterlooWelcomeMessage(e.target.value))
        }
      />
      <TextField
        id="outlined-multiline-static"
        label="Cornerbrook welcome message"
        multiline
        fullWidth
        sx={textFieldStyle}
        defaultValue={cornerbrook_welcomeMessage}
        onChange={(e) =>
          dispatch(updateCornerbrookWelcomeMessage(e.target.value))
        }
      />
      <TextField
        id="outlined-multiline-static"
        label="Fredericton welcome message"
        multiline
        fullWidth
        sx={textFieldStyle}
        defaultValue={fredericton_welcomeMessage}
        onChange={(e) =>
          dispatch(updateFrederictonWelcomeMessage(e.target.value))
        }
      />
      <TextField
        id="outlined-multiline-static"
        label="Goose bay welcome message"
        multiline
        fullWidth
        sx={textFieldStyle}
        defaultValue={goosebay_welcomeMessage}
        onChange={(e) => dispatch(updateGoosebayWelcomeMessage(e.target.value))}
      />
      <TextField
        id="outlined-multiline-static"
        label="Moncton welcome message"
        multiline
        fullWidth
        sx={textFieldStyle}
        defaultValue={moncton_welcomeMessage}
        onChange={(e) => dispatch(updateMonctonWelcomeMessage(e.target.value))}
      />
      <TextField
        id="outlined-multiline-static"
        label="Newfoundland welcome message"
        multiline
        fullWidth
        sx={textFieldStyle}
        defaultValue={newfoundland_welcomeMessage}
        onChange={(e) =>
          dispatch(updateNewfoundlandWelcomeMessage(e.target.value))
        }
      />
      <TextField
        id="outlined-multiline-static"
        label="Charlottetown welcome message"
        multiline
        fullWidth
        sx={textFieldStyle}
        defaultValue={charlottetown_welcomeMessage}
        onChange={(e) =>
          dispatch(updateCharlottetownWelcomeMessage(e.target.value))
        }
      />
      <TextField
        id="outlined-multiline-static"
        label="London welcome message"
        multiline
        fullWidth
        sx={textFieldStyle}
        defaultValue={london_welcomeMessage}
        onChange={(e) => dispatch(updateLondonWelcomeMessage(e.target.value))}
      />
      <TextField
        id="outlined-multiline-static"
        label="Newbrunswick welcome message"
        multiline
        fullWidth
        sx={textFieldStyle}
        defaultValue={newbrunswick_welcomeMessage}
        onChange={(e) =>
          dispatch(updateNewbrunswickWelcomeMessage(e.target.value))
        }
      />
      <TextField
        id="outlined-multiline-static"
        label="Sudbury welcome message"
        multiline
        fullWidth
        sx={textFieldStyle}
        defaultValue={sudbury_welcomeMessage}
        onChange={(e) => dispatch(updateSudburyWelcomeMessage(e.target.value))}
      />
      <TextField
        id="outlined-multiline-static"
        label="Windsor welcome message"
        multiline
        fullWidth
        sx={textFieldStyle}
        defaultValue={windsor_welcomeMessage}
        onChange={(e) => dispatch(updateWindsorWelcomeMessage(e.target.value))}
      />
      <TextField
        id="outlined-multiline-static"
        label="Iqualuit welcome message"
        multiline
        fullWidth
        sx={textFieldStyle}
        defaultValue={iqaluit_welcomeMessage}
        onChange={(e) => dispatch(updateIqualuitWelcomeMessage(e.target.value))}
      />
      <TextField
        id="outlined-multiline-static"
        label="Montreal welcome message"
        multiline
        fullWidth
        sx={textFieldStyle}
        defaultValue={montreal_welcomeMessage}
        onChange={(e) => dispatch(updateMontrealWelcomeMessage(e.target.value))}
      />
    </Box>
  );
}

export default LocalMessagePage;

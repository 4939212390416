import { Button, Box, Container } from "@mui/material";
import { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateConfig, updateConfigFromFile } from "../app/configSlice";
import { loadConfig } from "../app/appSlice";
import { SERVER_URL } from "../app/constant";
import DialogMessage from "./DialogMessage";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import { useUser } from "../auth/useUser";
import { ROLE } from "../app/constant";
import { useToken } from "../auth/useToken";

function DeploymentPage() {
  const [token, setToken] = useToken();
  const [openQA, setOpenQA] = useState(false);
  const [openProd, setOpenProd] = useState(false);
  const [openScheduleProd, setOpenScheduleProd] = useState(false);
  const [date, setDate] = useState();
  const [cancelSchedule, setCancelSchedule] = useState(false);
  const [deployScheduled, setDeployedScheduled] = useState();
  const [error, setError] = useState();
  const config = useSelector((state) => state.config);
  const dispatch = useDispatch();
  const user = useUser();
  const { role } = user;
  const inputFileRef = useRef(null);

  const checkConditions = () => {
    return date != null && error != null;
  };

  const saveConfig = () => {
    console.log("save config");
    const fileData = JSON.stringify(config);
    const blob = new Blob([fileData], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = "config.json";
    link.href = url;
    link.click();
  };

  const onFileChange = (event) => {
    dispatch(updateConfigFromFile(event)).then(dispatch(loadConfig()));
  };

  const onLoadConfigClick = () => {
    inputFileRef.current.click();
  };

  const handleClickOpenProd = () => {
    setOpenProd(true);
  };

  const handleCloseProd = () => {
    setOpenProd(false);
  };

  const handleClickOpenQA = () => {
    setOpenQA(true);
  };

  const handleCloseQA = () => {
    setOpenQA(false);
  };

  const handleClickScheduleProd = () => {
    setOpenScheduleProd(true);
  };

  const handleCloseScheduleProd = () => {
    setOpenScheduleProd(false);
  };

  const handleClickOpenCancelSchedule = () => {
    setCancelSchedule(true);
  };

  const handleCloseCancelSchedule = () => {
    setCancelSchedule(false);
  };

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(config),
  };

  const deployToProd = () => {
    setOpenProd(false);
    fetch(`${SERVER_URL}/update?env=prod`, requestOptions)
      .then((response) => console.log("config uploaded to prod"))
      .catch((error) => {
        console.log(error.message);
      });
  };

  const deployToQA = () => {
    setOpenQA(false);
    fetch(`${SERVER_URL}/update?env=qa`, requestOptions)
      .then((response) => {
        console.log("config uploaded to qa");
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const loadFromQA = () => {
    dispatch(updateConfig()).then(dispatch(loadConfig()));
  };

  useEffect(() => {
    getReleaseDate();
  }, []);

  const getReleaseDate = () => {
    fetch(`${SERVER_URL}/scheduledeploy/status`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data.status);
        // uncomment the line below once this endpoint is completed
        setDeployedScheduled(data.status);
        if (data.status) {
          setDate(data.releaseDate);
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const scheduleToProd = () => {
    console.log("schedule to prod button clicked");
    console.log("date: " + dayjs(date).format("DD/MM/YYYY HH:mm:ss"));

    fetch(`${SERVER_URL}/scheduledeploy`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        payload: JSON.stringify(config),
        releaseDate: date.toISOString(),
      }),
    })
      .then((response) => {
        console.log("config scheduled to prod");
        if (!response.ok) {
          throw new Error("Request failed with status " + response.status);
        }
        setDeployedScheduled(true);
      })
      .catch((error) => {
        console.log(error.message);
        setDeployedScheduled(false);
      });

    setOpenScheduleProd(false);
  };

  const cancelScheduledDeploy = () => {
    fetch(`${SERVER_URL}/scheduledeploy/cancel`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log("scheduled config deploy cancelled");
        setDeployedScheduled(false);
      })
      .catch((error) => {
        console.log(error.message);
      });

    setCancelSchedule(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "column",
      }}
    >
      <input
        type="file"
        id="file"
        ref={inputFileRef}
        style={{ display: "none" }}
        onChange={onFileChange}
        multiple={false}
        hidden
      />

      <Button
        variant="contained"
        sx={{ width: "20vw", mt: 2 }}
        onClick={saveConfig}
      >
        Save Config
      </Button>
      <Button
        variant="contained"
        sx={{ width: "20vw", mt: 2 }}
        onClick={onLoadConfigClick}
      >
        Load Config
      </Button>
      <Button
        variant="contained"
        sx={{ width: "20vw", mt: 2 }}
        onClick={loadFromQA}
      >
        Load from QA
      </Button>
      <Button
        variant="contained"
        sx={{ width: "20vw", mt: 30 }}
        onClick={handleClickOpenQA}
        disabled={role != ROLE.ADMIN}
      >
        Deploy to QA
      </Button>
      <Button
        variant="contained"
        onClick={handleClickOpenProd}
        sx={{ width: "20vw", mt: 2 }}
        disabled={role != ROLE.ADMIN}
      >
        Promote to Prod
      </Button>
      <Button
        variant="contained"
        onClick={
          deployScheduled
            ? handleClickOpenCancelSchedule
            : handleClickScheduleProd
        }
        sx={{ width: "20vw", mt: 2 }}
        disabled={role != ROLE.ADMIN}
        color={deployScheduled ? "success" : "primary"}
      >
        Scheduled Deployment
      </Button>
      <DialogMessage
        open={openQA}
        handleClose={handleCloseQA}
        title="Deploy config to QA?"
        message="Warning! This would overwrite the current QA config. Would you 
        like to deploy config to QA?"
        deployAction={deployToQA}
      />
      <DialogMessage
        open={openProd}
        handleClose={handleCloseProd}
        title="Deploy config to production?"
        message="Before deploying to production verify config in CBC Test. Would you
        like to deploy config to production?"
        deployAction={deployToProd}
      />
      <Dialog open={openScheduleProd} onClose={handleCloseScheduleProd}>
        <DialogTitle>
          {"Select a date and time to schedule the deployment"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Choose a date and time for when you want the changes to be deployed.
            After you're done, click on Confirm to schedule the change.
          </DialogContentText>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            {/* <DemoContainer components={["DateTimePicker"]}> */}
            {/* <Box fullWidth> */}
            <DateTimePicker
              label="Date and Time"
              value={date}
              disablePast
              onError={(newError) => setError(newError)}
              onChange={(newDate) => setDate(newDate)}
            />
            {/* </Box> */}
            {/* </DemoContainer> */}
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseScheduleProd}>Cancel</Button>
          <Button onClick={scheduleToProd} disabled={checkConditions()}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={cancelSchedule} onClose={handleCloseCancelSchedule}>
        <DialogTitle>{"The deployment has already been scheduled"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            It is currently scheduled to deploy at:
          </DialogContentText>
          <h3>{dayjs(date).format("MMM D, YYYY h:mmA")}</h3>
          <DialogContentText>
            Do you want to cancel the current scheduled deployment?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCancelSchedule}>Exit</Button>
          <Button onClick={cancelScheduledDeploy}>Cancel Deployment</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default DeploymentPage;

import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useToken } from "../auth/useToken";
import { Box, TextField, Button } from "@mui/material";
import { SERVER_URL } from "../app/constant";
import jwt_decode from "jwt-decode";

export const SignUpPage = () => {
  const [token, setToken] = useToken();
  const [errorMessage, setErrorMessage] = useState("");

  const [emailValue, setEmailValue] = useState("");
  const [passwordValue, setPasswordValue] = useState("");
  const [confirmPasswordValue, setConfirmPasswordValue] = useState("");

  const history = useHistory();

  const onSignUpClicked = async () => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: emailValue,
        password: passwordValue,
      }),
    };

    await fetch(`${SERVER_URL}/signup`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log("this is token ", data.token);
        setToken(data.token);
        history.push(`/please-verify?email=${encodeURIComponent(emailValue)}`);
      });
  };

  function handleCallbackResponse(response) {
    console.log("Encoded JWT ID token: " + response.credential);
    var userObject = jwt_decode(response.credential);
    console.log(userObject);
  }

  useEffect(() => {
    /* global google */
    google.accounts.id.initialize({
      client_id: "949161531967-g19rrq89h0eur9a7ni9nkvuk2sj069rc.apps.googleusercontent.com",
      callback: handleCallbackResponse
    });

    google.accounts.id.renderButton(
      document.getElementById("signUpDiv"),
      { theme: "outline", size: "large", width: "300px", mt: 3 }
    );
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <h1 style={{ color: "#3366BB" }}>Sign Up</h1>
      {errorMessage && <div className="fail">{errorMessage}</div>}
      <TextField
        sx={{ width: "300px", mt: 2 }}
        id="outlined-required"
        value={emailValue}
        label="Email"
        onChange={(e) => setEmailValue(e.target.value)}
      />
      <TextField
        sx={{ width: "300px", mt: 2 }}
        type="password"
        value={passwordValue}
        id="outlined-required"
        label="Password"
        onChange={(e) => setPasswordValue(e.target.value)}
        placeholder="password"
      />
      <TextField
        sx={{ width: "300px", mt: 2 }}
        type="password"
        id="outlined-required"
        label="Confirm Password"
        value={confirmPasswordValue}
        onChange={(e) => setConfirmPasswordValue(e.target.value)}
        error={passwordValue !== confirmPasswordValue}
      />
      <hr />
      <Button
        variant="contained"
        sx={{ width: "300px", mt: 2 }}
        disabled={
          !emailValue ||
          !passwordValue ||
          passwordValue !== confirmPasswordValue
        }
        onClick={onSignUpClicked}
      >
        Sign Up
      </Button>
      <h4 style={{ color: "#3366BB" }}>OR</h4>
      {<div id="signUpDiv"></div>}
      <Button sx={{ mt: 2 }} onClick={() => history.push("/login")}>
        Already have an account? Log In
      </Button>
    </Box>
  );
};
